import {
  Create,
  SimpleForm,
  DateInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin';
import { Grid } from '@mui/material';
import * as Yup from "yup";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { leastOneOption, validDate, number } from "../../../utils/schemas";
import config from "../../../config";

const optionRendererContract = contract => `${contract.client_name} - ${contract.name}`;
const Schema = Yup.object({
  contract_id: leastOneOption(),
  date_billing: validDate(),
  value_cost: number(),
  value_fees: number()
});

const EnterpriseInstallmentCreate = () => (
  <Create
    title="Nova Parcela"
    redirect="list">
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={<ToolbarSave />}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={6}>
          <ReferenceInput source="contract_id" sort={{ field: 'name', order: 'ASC' }} filter={{ status_contract: [ config.status_contract.in_day, config.status_contract.overdue ]}} reference="setup/enterprise/contracts">
            <AutocompleteInput label="Contrato" optionText={optionRendererContract} optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} fullWidth isRequired />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={2}>
          <DateInput source="date_billing" label="Data" parse={(date) => (date ? date.toISOString() : null)} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={2}>
          <NumberInput source="value_cost" label="Valor do Custo" step={1} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={2}>
          <NumberInput source="value_fees" label="Valor dos Juros" step={1} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </SimpleForm>
  </Create>
);

export default EnterpriseInstallmentCreate;
