import { SearchInput, ReferenceInput, SelectInput, DateInput, maxValue } from "react-admin";
import { Chip } from "@mui/material";
import moment from "moment";

const FilterCustomerRoute = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Status da Parcela" source="status_installment_type" reference="setup/customer/status-installment">
    <SelectInput
      label="Status da Parcela"
      optionText="name"
      optionValue="type"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <Chip source="show_absent" label="Ver Ausentes" defaultValue={true} />,
  <DateInput source="date_billing" label="Data de Início Cobrança" parse={(date) => (date ? date.toISOString() : null)} validate={maxValue(moment().format("YYYY-MM-DD"))} />,
  <DateInput source="date_end_billing" label="Data Final Cobrança" parse={(date) => (date ? date.toISOString() : null)} validate={maxValue(moment().format("YYYY-MM-DD"))} />
];

export default FilterCustomerRoute;
