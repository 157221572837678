import { Layout } from "react-admin";
import CustomSidebar from "./sidebar";
import CustomAppBar from "./appbar";
import Menu from "./menu";

function NewLayout(props)
{
  return (
    <Layout
      {...props}
      sidebar={CustomSidebar}
      appBar={CustomAppBar}
      menu={Menu} />
  );
}

export default NewLayout;
