import { SearchInput, ReferenceInput, SelectInput, DateInput } from "react-admin";

const FilterEnterpriseVehicleMaintenance = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Veículo" source="enterprise_vehicle_id" reference="setup/enterprise/vehicles">
    <SelectInput
      label="Veículo"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <DateInput source="date_init_maintenance" label="Data Inicial Manutenção" parse={(date) => (date ? date.toISOString() : null)} />,
  <DateInput source="date_end_maintenance" label="Data Final Manutenção" parse={(date) => (date ? date.toISOString() : null)} />
];

export default FilterEnterpriseVehicleMaintenance;
