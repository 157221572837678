import { usePermissions } from 'react-admin';
import WrapperDirection from "../wrapper-direction";
import DetailsButton from "../buttons/details-button";
import DialogInstallmentActions from "../dialogs/dialog-installment-actions";
import config from "../../config";

const ButtonsInstallment = () =>
{
  const { permissions } = usePermissions();

  return (
    <WrapperDirection>
      { permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.installments.show)) && <DetailsButton /> }
      <DialogInstallmentActions isEnterprise={true} isMainInstallment={true} />
    </WrapperDirection>
  );
};

export default ButtonsInstallment;
