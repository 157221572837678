import { Stack } from "@mui/system";

function WrapperDirection({ children, direction = "row", gap = 1 })
{
  return (
    <Stack direction={direction} gap={gap}>
      {children}
    </Stack>
  );
}

export default WrapperDirection;
