import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  FunctionField,
  DateField,
  Datagrid,
  ListBase,
  Labeled,
  useShowController
} from 'react-admin';
import { Grid } from "@mui/material";
import config from '../../../config';
import formatValue from "../../../utils/currency";
import rowInstallmentPenaltyStyle from '../../../ra/datagrid/row/installment-penalty';
import InstallmentPenaltyPanel from '../../../panels/enterprise/installment-penalty-panel';
import DialogInstallmentActions from '../../../components/dialogs/dialog-installment-actions';

const DatagridInstallmentsPenalties = ({ children, actions, filters, title, ...props }) =>
{
  const { record, loading } = useShowController();

  if (!loading && record)
  {
    var data = [];
    var dataWithPayment = [];
    var dataWithOutPayment = [];

    if (record.installments !== null && Array.isArray(record.installments))
    {
      record.installments.forEach((item, i) =>
      {
        var obj =
        {
          type_item: config.type_item_contract.installment,
          id: item.id,
          status_installment_type: item.status_installment_type,
          status_installment_name: item.status_installment_name,
          number: (item.number + " - " + item.count_installments),
          value_original: item.value_original,
          value_cost: item.value_cost,
          value_fees: item.value_fees,
          value_total: item.value_total,
          original_date_billing: item.original_date_billing_format,
          date_billing: item.date_billing,
          date: item.date_billing,
          customer_name: (item.payment ? item.payment.customer_name : ''),
          payment_form_name: (item.payment ? item.payment.enterprise_payment_form_name : ''),
          value_payment: (item.payment ? item.payment.value_format : ''),
          value_gain: (item.payment ? item.payment.value_gain : null),
          date_payment: (item.payment ? item.payment.date_payment : ''),
          created_at: item.created_at
        };

        if (item.payment)
          dataWithPayment.push(obj);
        else
          dataWithOutPayment.push(obj);
      });
    }

    if (record.penalties !== null && Array.isArray(record.penalties))
    {
      record.penalties.forEach((item, i) =>
      {
        dataWithPayment.push(
        {
          type_item: config.type_item_contract.penalty,
          id: item.id,
          status_installment_type: 0,
          status_installment_name: 'Paga',
          number: 'Multa',
          value_original: item.value,
          value_cost: 0,
          value_fees: item.value,
          value_total: item.value,
          original_date_billing: item.date_payment,
          date_billing: item.date_payment,
          customer_name: item.customer_name,
          payment_form_name: item.enterprise_payment_form_name,
          value_payment: item.value_format,
          value_gain: item.value,
          date_payment: item.date_payment,
          created_at: item.created_at
        });
      });
    }

    // SORT ARRAY WITH PAYMENT BY DATE PAYMENT
    dataWithPayment.sort((a, b) => (new Date(a.date_payment) > new Date(b.date_payment)) ? 1 : -1);

    // SORT ARRAY WITHOUT PAYMENT BY DATE BILLING
    dataWithOutPayment.sort((a, b) => (new Date(a.date_billing) > new Date(b.date_billing)) ? 1 : -1);

    // CONCAT ARRAYS
    data = data.concat(dataWithPayment).concat(dataWithOutPayment);

    return (
      <ListBase>
        <Datagrid
          data={data}
          rowClick={false}
          bulkActionButtons={false}
          expandSingle
          expand={<InstallmentPenaltyPanel />}
          isRowExpandable={(row) => row.type_item === config.type_item_contract.installment}
          rowStyle={rowInstallmentPenaltyStyle()}>
          <TextField source="status_installment_name" label="Status" sortable={false} />
          <TextField source="number" label="Número da Parcela" sortable={false} />
          <FunctionField source="value_original" label="Valor Original" render={record => `${formatValue(record.value_original)}`} sortable={false} />
          <FunctionField source="value_total" label="Valor Total" render={record => `${formatValue(record.value_total)}`} sortable={false} />
          <DateField source="date_billing" locales="pt-BR" label="Data de Cobrança" sortable={false} />
          <TextField source="customer_name" label="Cobrada Por" sortable={false} />
          <TextField source="payment_form_name" label="Forma" sortable={false} />
          <TextField source="value_payment" label="Valor Recebido" sortable={false} />
          <FunctionField source="value_gain" label="Valor do Lucro" render={record => `${formatValue(record.value_gain)}`} sortable={false} />
          <DateField source="date_payment" locales="pt-BR" label="Data do Pagamento" showTime sortable={false} />
          <DialogInstallmentActions isEnterprise={true} isMainInstallment={false} />
        </Datagrid>
      </ListBase>
    );
  }
  else
    return null;
}

const ContractShow = (props) => (
  <Show
    title="Contrato">
    <TabbedShowLayout>
      <Tab label="Dados">
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="id" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="status_contract_name" label="Status" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="client_name" label="Cliente" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="name" label="Nome" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="enterprise_billing_period_name" label="Período de Pagamento" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="percentage" label="Porcentagem" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="date_init_format" label="Data de Início" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <TextField source="user_enterprise.name" label="Criado Por" />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />
        </Grid>
      </Tab>
      <Tab label="Valores">
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <FunctionField source="value_initial" label="Valor Emprestado" render={record => `${formatValue(record.value_initial)}`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <FunctionField source="value_expected" label="Valor Esperado" render={record => `${formatValue(record.value_expected)}`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <FunctionField source="value_cost" label="Valor Investido" render={record => `${formatValue(record.value_cost)}`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <FunctionField source="value_fees" label="Valor Juros" render={record => `${formatValue(record.value_fees)}`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <FunctionField source="value_total" label="Valor Total" render={record => `${formatValue(record.value_total)}`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <FunctionField source="value_paid" label="Valor Pago" render={record => `${formatValue(record.value_paid)}`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <FunctionField source="value_to_receive" label="Valor a Receber" render={record => `${formatValue(record.value_to_receive)}`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <FunctionField source="value_extended" label="Valor Prorrogado" render={record => `${formatValue(record.value_extended)}`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <FunctionField source="value_canceled" label="Valor Cancelado" render={record => `${formatValue(record.value_canceled)}`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <Labeled>
              <FunctionField source="value_penalties" label="Valor Multas" render={record => `${formatValue(record.value_penalties)}`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Labeled>
              <FunctionField source="value_gain" label="Valor do Lucro" render={record => `${formatValue(record.value_gain)}`} />
            </Labeled>
          </Grid>
          <Grid item xs={12} sm={8} />
        </Grid>
      </Tab>
      <Tab label="Parcelas/Multas">
        <DatagridInstallmentsPenalties />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default ContractShow;
