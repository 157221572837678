import {
  ListBase,
  Title,
  ListToolbar,
  Datagrid,
  FunctionField,
  useListController
} from 'react-admin';
import config from '../../../config';
import { Card } from '@mui/material';
import Button from "../../../components/buttons/button";
import FilterCustomerRoute from '../../../filters/customer/route';
import Actions from "../../../ra/list/actions";
import WrapperDirection from "../../../components/wrapper-direction";
import ButtonClientAddress from '../../../components/buttons/button-client-address';
import ButtonClientEnterpriseAddress from '../../../components/buttons/button-client-enterprise-address';
import ButtonClientPhone from '../../../components/buttons/button-client-phone';
import ButtonClientPhoneWhatsapp from '../../../components/buttons/button-client-phone-whatsapp';
import DialogRouteActions from '../../../components/dialogs/dialog-route-actions';
import RoutePanel from '../../../panels/customer/route-panel';
import IconMaps from '@mui/icons-material/MyLocation';
import formatValue from "../../../utils/currency";
import rowRouteStyle from '../../../ra/datagrid/row/route';

const CustomList = ({ children, actions, filters, title, ...props }) =>
{
  const { data, loading } = useListController();

  const btnClick = () =>
  {
    window.open(mapsUrl);
  };

  if (!loading && data)
  {
    var mapsUrl = 'https://www.google.com/maps/dir/?api=1&travelmode=driving&';
    var wayPoints = '';

    data.forEach((item, i) =>
    {
      // CLIENT PROSPECTION
      if (item.type === config.type_item_route.client_prospection)
      {
        if (i === (data.length - 1))
          mapsUrl += ("&destination=" + item.enterprise_address.lat + "," + item.enterprise_address.lng);
        else
          wayPoints += (item.enterprise_address.lat + "," + item.enterprise_address.lng + "|");
      }
      // INSTALLMENT
      else if (item.type === config.type_item_route.installment)
      {
        if (i === (data.length - 1))
          mapsUrl += ("&destination=" + item.client.enterprise_address.lat + "," + item.client.enterprise_address.lng);
        else
          wayPoints += (item.client.enterprise_address.lat + "," + item.client.enterprise_address.lng + "|");
      }
    });

    if (wayPoints !== '')
      mapsUrl += ("&waypoints=" + wayPoints);

    return (
      <ListBase {...props}>
        <Title title={title}/>
        <ListToolbar filters={filters} actions={actions}/>
        <Card>
        {children}
        </Card>
        <br/>
        <div>
          <Button
            onClick={btnClick}
            label="Traçar Rota">
            <IconMaps />
          </Button>
          <br/>
          <br/>
        </div>
      </ListBase>
    );
  }
  else
    return null;
}

const RouteList = (props) => (
  <CustomList
    title="Rotas"
    sort={{ field: 'id', order: 'ASC' }}
    perPage={100}
    filters={ FilterCustomerRoute }
    actions={ <Actions hasCreate={false} hasExport={false} /> }>
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}
      expandSingle
      expand={<RoutePanel />}
      isRowExpandable={(row) => row.type === config.type_item_route.installment}
      rowStyle={rowRouteStyle()}>
      <FunctionField render={record => record.type === config.type_item_route.client_prospection ? `` : `${record.number}/${record.count_installments}`} label="Número" sortable={false} />
      <FunctionField render={record => record.type === config.type_item_route.client_prospection ? `${record.name}` : `${record.client.name}`} label="Cliente" sortable={false} />
      <FunctionField render={record => record.type === config.type_item_route.client_prospection ? `${record.enterprise_region.name}` : `${record.client.enterprise_region.name}`} label="Região" sortable={false} />
      <FunctionField render={record => record.type === config.type_item_route.client_prospection ? `` : `${formatValue(record.value_total)}`} label="Valor" sortable={false} />
      <FunctionField render={record => record.type === config.type_item_route.client_prospection ? `` : `${record.date_billing_format}`} label="Data de Cobrança" sortable={false} />
      <WrapperDirection>
        <ButtonClientAddress />
        <ButtonClientEnterpriseAddress />
        <ButtonClientPhone />
        <ButtonClientPhoneWhatsapp />
        <DialogRouteActions isEnterprise={false} isClient={false} isMainInstallment={true} />
      </WrapperDirection>
    </Datagrid>
  </CustomList>
);

export default RouteList;
