import config from "../config";
import jwt from "jwt-simple";

const getLocalStorageUser = () =>
{
  return config.local_storage.user;
};

const getLocalStorageAuth = () =>
{
  return config.local_storage.auth;
};

const getLocalStorageLocation = () =>
{
  return config.local_storage.location;
};

const getLocalStorageDate = () =>
{
  return config.local_storage.date;
};

const getLocalStorageFilterChartFinancial = () =>
{
  return config.local_storage.filter_chart_financial;
};

const getLocalStorageFilterChartClients = () =>
{
  return config.local_storage.filter_chart_clients;
};

const getLocalStorageFilterChartClientsPerRegion = () =>
{
  return config.local_storage.filter_chart_clients_per_region;
};

const getLocalStorageFilterChartClientsPerNiche = () =>
{
  return config.local_storage.filter_chart_clients_per_niche;
};

const getLocalStorageFilterChartContracts = () =>
{
  return config.local_storage.filter_chart_contracts;
};

const getLocalStorageFilterChartContractsPerBillingPeriod = () =>
{
  return config.local_storage.filter_chart_contracts_per_billing_period;
};

const getLocalStorageFilterChartWithdrawalsPerType = () =>
{
  return config.local_storage.filter_chart_withdrawals_per_type;
};

const getLocalStorageFilterChartWithdrawalsPerCustomer = () =>
{
  return config.local_storage.filter_chart_withdrawals_per_customer;
};

const getLocalStorageFilterChartInvoicesPerType = () =>
{
  return config.local_storage.filter_chart_invoices_per_type;
};

const getUrlApi = () =>
{
  return config.api.url;
};

const getApiKey = () =>
{
  return config.api.api_key;
};

const getJwtKey = () =>
{
  return config.jwt.key;
};

const getAuthToken = () =>
{
  if (localStorage.getItem(jwt.encode(getLocalStorageAuth(), getJwtKey())) !== null)
    return JSON.parse(
      jwt.decode(
        localStorage.getItem(jwt.encode(getLocalStorageAuth(), getJwtKey())),
        getJwtKey()
      )
    ).auth_token;
  else
    return null;
};

const getRequestSign = (type_user, email, password) =>
{
  return new Request(getUrlApi() + '/' + type_user + `/sign`,
  {
    method: "POST",
    body: JSON.stringify({ email: email, password: password }),
    headers: new Headers({
      "Content-Type": "application/json",
      "x-api-key": getApiKey()
    })
  });
};

const getRequestAdminMe = () =>
{
  return {
    method: "GET",
    url: (getUrlApi() + '/admin/me'),
    headers:
    {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": getApiKey(),
      "x-token": "Bearer " + getAuthToken()
    }
  };
};

const getRequestEnterpriseMe = () =>
{
  return {
    method: "GET",
    url: (getUrlApi() + '/user-enterprise/me'),
    headers:
    {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": getApiKey(),
      "x-token": "Bearer " + getAuthToken()
    }
  };
};

const getRequestCustomerMe = () =>
{
  return {
    method: "GET",
    url: (getUrlApi() + '/customer/me'),
    headers:
    {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": getApiKey(),
      "x-token": "Bearer " + getAuthToken()
    }
  };
};

const getHeadersFormData = () =>
{
  return {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    "x-api-key": getApiKey(),
    "x-token": "Bearer " + getAuthToken()
  };
};

const getCurrentUser = () =>
{
  if (localStorage.getItem(jwt.encode(getLocalStorageUser(), getJwtKey())) !== null)
    return JSON.parse(
      jwt.decode(
        localStorage.getItem(jwt.encode(getLocalStorageUser(), getJwtKey())),
        getJwtKey()
      )
    );
  else
    return null;
};

const getCurrentDate = () =>
{
  if (localStorage.getItem(jwt.encode(getLocalStorageDate(), getJwtKey())) !== null)
    return JSON.parse(
      jwt.decode(
        localStorage.getItem(jwt.encode(getLocalStorageDate(), getJwtKey())),
        getJwtKey()
      )
    );
  else
    return null;
};

const getFilterChartFinancial = () =>
{
  if (localStorage.getItem(jwt.encode(getLocalStorageFilterChartFinancial(), getJwtKey())) !== null)
    return JSON.parse(
      jwt.decode(
        localStorage.getItem(jwt.encode(getLocalStorageFilterChartFinancial(), getJwtKey())),
        getJwtKey()
      )
    );
  else
    return null;
};

const getFilterChartClients = () =>
{
  if (localStorage.getItem(jwt.encode(getLocalStorageFilterChartClients(), getJwtKey())) !== null)
    return JSON.parse(
      jwt.decode(
        localStorage.getItem(jwt.encode(getLocalStorageFilterChartClients(), getJwtKey())),
        getJwtKey()
      )
    );
  else
    return null;
};

const getFilterChartClientsPerRegion = () =>
{
  if (localStorage.getItem(jwt.encode(getLocalStorageFilterChartClientsPerRegion(), getJwtKey())) !== null)
    return JSON.parse(
      jwt.decode(
        localStorage.getItem(jwt.encode(getLocalStorageFilterChartClientsPerRegion(), getJwtKey())),
        getJwtKey()
      )
    );
  else
    return null;
};

const getFilterChartClientsPerNiche = () =>
{
  if (localStorage.getItem(jwt.encode(getLocalStorageFilterChartClientsPerNiche(), getJwtKey())) !== null)
    return JSON.parse(
      jwt.decode(
        localStorage.getItem(jwt.encode(getLocalStorageFilterChartClientsPerNiche(), getJwtKey())),
        getJwtKey()
      )
    );
  else
    return null;
};

const getFilterChartContracts = () =>
{
  if (localStorage.getItem(jwt.encode(getLocalStorageFilterChartContracts(), getJwtKey())) !== null)
    return JSON.parse(
      jwt.decode(
        localStorage.getItem(jwt.encode(getLocalStorageFilterChartContracts(), getJwtKey())),
        getJwtKey()
      )
    );
  else
    return null;
};

const getFilterChartContractsPerBillingPeriod = () =>
{
  if (localStorage.getItem(jwt.encode(getLocalStorageFilterChartContractsPerBillingPeriod(), getJwtKey())) !== null)
    return JSON.parse(
      jwt.decode(
        localStorage.getItem(jwt.encode(getLocalStorageFilterChartContractsPerBillingPeriod(), getJwtKey())),
        getJwtKey()
      )
    );
  else
    return null;
};

const getFilterChartWithdrawalsPerType = () =>
{
  if (localStorage.getItem(jwt.encode(getLocalStorageFilterChartWithdrawalsPerType(), getJwtKey())) !== null)
    return JSON.parse(
      jwt.decode(
        localStorage.getItem(jwt.encode(getLocalStorageFilterChartWithdrawalsPerType(), getJwtKey())),
        getJwtKey()
      )
    );
  else
    return null;
};

const getFilterChartWithdrawalsPerCustomer = () =>
{
  if (localStorage.getItem(jwt.encode(getLocalStorageFilterChartWithdrawalsPerCustomer(), getJwtKey())) !== null)
    return JSON.parse(
      jwt.decode(
        localStorage.getItem(jwt.encode(getLocalStorageFilterChartWithdrawalsPerCustomer(), getJwtKey())),
        getJwtKey()
      )
    );
  else
    return null;
};

const getFilterChartInvoicesPerType = () =>
{
  if (localStorage.getItem(jwt.encode(getLocalStorageFilterChartInvoicesPerType(), getJwtKey())) !== null)
    return JSON.parse(
      jwt.decode(
        localStorage.getItem(jwt.encode(getLocalStorageFilterChartInvoicesPerType(), getJwtKey())),
        getJwtKey()
      )
    );
  else
    return null;
};

const setCurrentUser = (user) =>
{
  localStorage.setItem(
    jwt.encode(getLocalStorageUser(), getJwtKey()),
    jwt.encode(JSON.stringify(user), getJwtKey())
  );
};

const setCurrentAuth = (auth) =>
{
  localStorage.setItem(
    jwt.encode(getLocalStorageAuth(), getJwtKey()),
    jwt.encode(JSON.stringify(auth), getJwtKey())
  );
};

const setLocation = (location) =>
{
  localStorage.setItem(
    jwt.encode(getLocalStorageLocation(), getJwtKey()),
    jwt.encode(JSON.stringify(location), getJwtKey())
  );
};

const setCurrentDate = (date) =>
{
  localStorage.setItem(
    jwt.encode(getLocalStorageDate(), getJwtKey()),
    jwt.encode(JSON.stringify(date), getJwtKey())
  );
};

const setFilterChartFinancial = (countMonths) =>
{
  localStorage.setItem(
    jwt.encode(getLocalStorageFilterChartFinancial(), getJwtKey()),
    jwt.encode(JSON.stringify(countMonths), getJwtKey())
  );
};

const setFilterChartClients = (countMonths) =>
{
  localStorage.setItem(
    jwt.encode(getLocalStorageFilterChartClients(), getJwtKey()),
    jwt.encode(JSON.stringify(countMonths), getJwtKey())
  );
};

const setFilterChartClientsPerRegion = (countMonths, regions) =>
{
  localStorage.setItem(
    jwt.encode(getLocalStorageFilterChartClientsPerRegion(), getJwtKey()),
    jwt.encode(JSON.stringify({ count_months: countMonths, regions: regions }), getJwtKey())
  );
};

const setFilterChartClientsPerNiche = (countMonths, niches) =>
{
  localStorage.setItem(
    jwt.encode(getLocalStorageFilterChartClientsPerNiche(), getJwtKey()),
    jwt.encode(JSON.stringify({ count_months: countMonths, niches: niches }), getJwtKey())
  );
};

const setFilterChartContracts = (countMonths) =>
{
  localStorage.setItem(
    jwt.encode(getLocalStorageFilterChartContracts(), getJwtKey()),
    jwt.encode(JSON.stringify(countMonths), getJwtKey())
  );
};

const setFilterChartContractsPerBillingPeriod = (countMonths, billing_periods) =>
{
  localStorage.setItem(
    jwt.encode(getLocalStorageFilterChartContractsPerBillingPeriod(), getJwtKey()),
    jwt.encode(JSON.stringify({ count_months: countMonths, billing_periods: billing_periods }), getJwtKey())
  );
};

const setFilterChartWithdrawalsPerType = (countMonths, types_withdrawal) =>
{
  localStorage.setItem(
    jwt.encode(getLocalStorageFilterChartWithdrawalsPerType(), getJwtKey()),
    jwt.encode(JSON.stringify({ count_months: countMonths, types_withdrawal: types_withdrawal }), getJwtKey())
  );
};

const setFilterChartWithdrawalsPerCustomer = (countMonths, customers) =>
{
  localStorage.setItem(
    jwt.encode(getLocalStorageFilterChartWithdrawalsPerCustomer(), getJwtKey()),
    jwt.encode(JSON.stringify({ count_months: countMonths, customers: customers }), getJwtKey())
  );
};

const setFilterChartInvoicesPerType = (countMonths, types_invoice) =>
{
  localStorage.setItem(
    jwt.encode(getLocalStorageFilterChartInvoicesPerType(), getJwtKey()),
    jwt.encode(JSON.stringify({ count_months: countMonths, types_invoice: types_invoice }), getJwtKey())
  );
};

const clearLocalStorage = () =>
{
  localStorage.removeItem(jwt.encode(getLocalStorageUser(), getJwtKey()));
  localStorage.removeItem(jwt.encode(getLocalStorageAuth(), getJwtKey()));
  localStorage.removeItem(jwt.encode(getLocalStorageLocation(), getJwtKey()));
  localStorage.removeItem(jwt.encode(getLocalStorageDate(), getJwtKey()));
  localStorage.removeItem(jwt.encode(getLocalStorageFilterChartFinancial(), getJwtKey()));
  localStorage.removeItem(jwt.encode(getLocalStorageFilterChartClients(), getJwtKey()));
  localStorage.removeItem(jwt.encode(getLocalStorageFilterChartClientsPerRegion(), getJwtKey()));
  localStorage.removeItem(jwt.encode(getLocalStorageFilterChartClientsPerNiche(), getJwtKey()));
  localStorage.removeItem(jwt.encode(getLocalStorageFilterChartContracts(), getJwtKey()));
  localStorage.removeItem(jwt.encode(getLocalStorageFilterChartContractsPerBillingPeriod(), getJwtKey()));
  localStorage.removeItem(jwt.encode(getLocalStorageFilterChartWithdrawalsPerType(), getJwtKey()));
  localStorage.removeItem(jwt.encode(getLocalStorageFilterChartWithdrawalsPerCustomer(), getJwtKey()));
  localStorage.removeItem(jwt.encode(getLocalStorageFilterChartInvoicesPerType(), getJwtKey()));
};

export {
  getUrlApi,
  getRequestSign,
  getRequestAdminMe,
  getRequestEnterpriseMe,
  getRequestCustomerMe,
  getHeadersFormData,
  getCurrentUser,
  getCurrentDate,
  getFilterChartFinancial,
  getFilterChartClients,
  getFilterChartClientsPerRegion,
  getFilterChartClientsPerNiche,
  getFilterChartContracts,
  getFilterChartContractsPerBillingPeriod,
  getFilterChartWithdrawalsPerType,
  getFilterChartWithdrawalsPerCustomer,
  getFilterChartInvoicesPerType,
  setCurrentUser,
  setCurrentAuth,
  setLocation,
  setCurrentDate,
  setFilterChartFinancial,
  setFilterChartClients,
  setFilterChartClientsPerRegion,
  setFilterChartClientsPerNiche,
  setFilterChartContracts,
  setFilterChartContractsPerBillingPeriod,
  setFilterChartWithdrawalsPerType,
  setFilterChartWithdrawalsPerCustomer,
  setFilterChartInvoicesPerType,
  clearLocalStorage
};
