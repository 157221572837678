import {
  Create,
  FormTab,
  TabbedForm,
  TabbedFormTabs,
  ReferenceInput,
  SelectInput,
  DateInput,
  TextInput,
  NumberInput,
  FileInput,
  ArrayInput,
  SimpleFormIterator,
  FileField,
  Labeled,
  useCreate,
  useNotify,
  useRedirect
} from 'react-admin';
import { Grid } from "@mui/material";
import { useState } from "react";
import * as Yup from "yup";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { leastOneOption, hasValue, document, phone, cellphone, file } from "../../../utils/schemas";
import GooglePlacesInput from "../../../components/inputs/google-places-input";
import MaskTextInput from "../../../components/inputs/mask-text-input";

const Schema = Yup.object({
  employee_situation_type: leastOneOption(),
  name: hasValue(),
  document: document(),
  phone: phone(false),
  cellphone: cellphone(false),
  enterprise_employee_files: Yup.array().of(
    Yup.object().shape(
    {
      file_name: hasValue(),
      file: file()
    })
  )
});

const EnterpriseEmployeeCreate = () =>
{
  const [create] = useCreate();
  const redirect = useRedirect();
  const notify = useNotify();
  const [address, setAddress] = useState(null);

  const employeeSave = (data) =>
  {
    if (address)
      data.address = address;

    create("enterprise-employee", { data: data }, { onSuccess: (employee) => { redirect("/enterprise-employee"); },
    onError: (error) => { notify("Já existe um funcionário com esse CPF na sua empresa.", { type: "error" }); }});
  };

  return (
    <Create
      title="Novo Funcionário"
      redirect="list">
      <TabbedForm
        noValidate
        onSubmit={employeeSave}
        resolver={yupResolver(Schema)}
        tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
        toolbar={<ToolbarSave />}>
        <FormTab label="Dados">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <ReferenceInput source="employee_situation_type" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/employee-situations">
                <SelectInput label="Situação" optionText="name" optionValue="type" fullWidth isRequired />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={2}>
              <TextInput source="name" label="Nome" fullWidth isRequired />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MaskTextInput source="document" label="CPF" mask="###.###.###-##" fullWidth isRequired />
            </Grid>
            <Grid item xs={12} sm={2}>
              <DateInput source="birthdate" label="Data Nascimento" parse={(date) => (date ? date.toISOString() : null)} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={2}>
              <TextInput source="nationality" label="Nacionalidade" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MaskTextInput source="phone" label="Telefone Fixo" mask="(##) ####-####" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MaskTextInput source="cellphone" label="Celular Principal" mask="(##) #####-####" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={2}>
              <TextInput source="work_document" label="Carteira de Trabalho" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput source="social_document" label="PIS/PASEP" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput source="voter_document" label="Título de Eleitor" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={2}>
              <TextInput source="identifier_document" label="Identidade" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput source="key_transfer" label="Chave PIX" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />
          </Grid>
        </FormTab>

        <FormTab label="Contrato">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={2}>
              <TextInput source="contract_type" label="Tipo de Contrato" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput source="office" label="Cargo/Função" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <NumberInput source="value_salary" label="Salário" step={1} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={2}>
              <TextInput source="workplace" label="Local de Trabalho" fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <DateInput source="admission_date" label="Data de Admissão" parse={(date) => (date ? date.toISOString() : null)} fullWidth />
            </Grid>
            <Grid item xs={12} sm={8} />

            <Grid item xs={12} sm={4}>
              <TextInput source="working_day" label="Jornada de Trabalho" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />
          </Grid>
        </FormTab>

        <FormTab label="Endereço">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <Labeled label="Endereço" fullWidth>
                <GooglePlacesInput source="address" setAddress={setAddress} />
              </Labeled>
            </Grid>
            <Grid item xs={12} sm={6} />

            <Grid item xs={12} sm={6}>
              <TextInput source="address_complement" label="Complemento do Endereço" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6} />
          </Grid>
        </FormTab>

        <FormTab label="Arquivo(s)">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <ArrayInput label="Arquivo(s)" source="enterprise_employee_files">
                <SimpleFormIterator disableReordering fullWidth>
                  <TextInput source="file_name" label="Nome" fullWidth isRequired />
                  <FileInput source="file" label="Arquivo" isRequired>
                    <FileField source="src" title="title" />
                  </FileInput>
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
            <Grid item xs={12} sm={6} />
          </Grid>
        </FormTab>
      </TabbedForm>
    </Create>
  )
};

export default EnterpriseEmployeeCreate;
