import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  DateTimeInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  useRecordContext,
  useEditController,
  usePermissions
} from 'react-admin';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import Title from "../../../ra/edit/title";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { validDate, number, leastOneOption } from "../../../utils/schemas";
import { getCustomersId } from '../../../utils/utils';
import config from '../../../config';

const Schema = (record) =>
{
  var obj =
  {
    date_billing: validDate(),
    value_cost: number(),
    value_fees: number()
  };

  if (record && record.payment != null)
  {
    obj.payment = Yup.object().shape({
      customer_id: leastOneOption(),
      enterprise_payment_form_id: leastOneOption(),
      date_payment: validDate(),
      value: number()
    });
  }

  return Yup.object(obj);
}

const PaymentValueField = ({ hasPermissionEditDates, customersId }) =>
{
  const record = useRecordContext();
  if (record.payment != null)
    return (
      <>
        <Grid item xs={12} sm={3}>
          <ReferenceInput source="payment.customer_id" sort={{ field: 'name', order: 'ASC' }} filter={{ customers: customersId }} reference="setup/enterprise/customers" >
            <SelectInput label="Colaborador" optionText="name" optionValue="id" fullWidth isRequired />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={3}>
          <ReferenceInput source="payment.enterprise_payment_form_id" sort={{ field: 'name', order: 'ASC' }} filter={{ allow_enterprise_installment: true }} reference={"setup/enterprise/payment-forms"}>
            <SelectInput label="Forma de Pagamento" optionText="name" optionValue="id" fullWidth isRequired />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={3}>
          <DateTimeInput source="payment.date_payment" label="Data do Pagamento" fullWidth readOnly={!hasPermissionEditDates} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NumberInput source="payment.value" label="Valor Recebido" step={1} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />
      </>
    );
  else
    return null;
};

const EnterpriseInstallmentEdit = () =>
{
  const { record } = useEditController();
  const { permissions } = usePermissions();
  const hasPermissionEditDates = permissions.permissions.some((e) => e.keys.some(k => k.key === config.permissions.installments.edit_dates_billing_and_payment));

  return (
    <Edit
      title={ <Title subtitle="Editar Parcela" source="contract_name" /> }
      redirect="list"
      mutationMode="pessimistic">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema(record))}
        toolbar={ <ToolbarSave /> }>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={2}>
            <TextInput source="id" label="Id" fullWidth disabled />
          </Grid>
          <Grid item xs={12} sm={10} />

          <Grid item xs={12} sm={6}>
            <TextInput source="contract_name" label="Contrato" fullWidth disabled />
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={3}>
            <DateInput source="original_date_billing" label="Data Original" parse={(date) => (date ? date.toISOString() : null)} fullWidth disabled />
          </Grid>
          <Grid item xs={12} sm={3}>
            <NumberInput source="value_original" label="Valor Original" fullWidth disabled />
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <DateInput source="date_billing" label="Data" parse={(date) => (date ? date.toISOString() : null)} fullWidth isRequired readOnly={!hasPermissionEditDates} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <NumberInput source="value_cost" label="Valor do Custo" step={1} fullWidth isRequired />
          </Grid>
          <Grid item xs={12} sm={2}>
            <NumberInput source="value_fees" label="Valor dos Juros" step={1} fullWidth isRequired />
          </Grid>
          <Grid item xs={12} sm={6} />

          <PaymentValueField
            hasPermissionEditDates={hasPermissionEditDates}
            customersId={getCustomersId(permissions, config.permissions.installment_actions.key)} />
        </Grid>
      </SimpleForm>
    </Edit>
  )
}

export default EnterpriseInstallmentEdit
