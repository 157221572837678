import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  DateInput,
  DateTimeInput,
  NumberInput,
  useRecordContext,
  useEditController
} from 'react-admin';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import Title from "../../../ra/edit/title";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { leastOneOption, hasValue, number, validDate } from "../../../utils/schemas";

const Schema = (record) =>
{
  var obj =
  {
    enterprise_vehicle_id: leastOneOption(),
    customer_id: leastOneOption(),
    type_penalty: hasValue(),
    value: number(),
    date_penalty: validDate(),
    date_due: validDate()
  };

  if (record && record.payment != null)
  {
    obj.payment = Yup.object().shape({
      enterprise_bank_account_id: leastOneOption(),
      enterprise_payment_form_id: leastOneOption(),
      date_payment: validDate(),
      value: number()
    });
  }

  return Yup.object(obj);
}

const PaymentValueField = () =>
{
  const record = useRecordContext();
  if (record.payment != null)
    return (
      <>
        <Grid item xs={12} sm={6}>
          <TextInput source="payment.user_enterprise_name" label="Paga Por" fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={3}>
          <ReferenceInput source="payment.enterprise_bank_account_id" sort={{ field: 'name', order: 'ASC' }} reference={"setup/enterprise/bank-accounts"}>
            <SelectInput label="Conta Bancária" optionText="name" optionValue="id" fullWidth isRequired />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={3}>
          <ReferenceInput source="payment.enterprise_payment_form_id" sort={{ field: 'name', order: 'ASC' }} reference={"setup/enterprise/payment-forms"}>
            <SelectInput label="Forma de Pagamento" optionText="name" optionValue="id" fullWidth isRequired />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={3}>
          <DateTimeInput source="payment.date_payment" label="Data do Pagamento" fullWidth />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NumberInput source="payment.value" label="Valor Recebido" step={1} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />
      </>
    );
  else
    return null;
};

const EnterpriseVehiclePenaltyEdit = () =>
{
  const { record } = useEditController();

  return (
    <Edit
      title={ <Title subtitle="Editar Infração" source="type_penalty" /> }
      redirect="list"
      mutationMode="pessimistic">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema(record))}
        toolbar={ <ToolbarSave /> }>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={2}>
            <TextInput source="id" label="Id" fullWidth disabled />
          </Grid>
          <Grid item xs={12} sm={10} />

          <Grid item xs={12} sm={3}>
            <ReferenceInput source="enterprise_vehicle_id" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/vehicles">
              <SelectInput label="Veículo" optionText="name" optionValue="id" fullWidth isRequired />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={3}>
            <ReferenceInput source="customer_id" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/customers">
              <SelectInput label="Condutor" optionText="name" optionValue="id" fullWidth isRequired />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={6}>
            <TextInput source="type_penalty" label="Tipo" fullWidth isRequired />
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <NumberInput source="value" label="Valor" step={1} fullWidth isRequired />
          </Grid>
          <Grid item xs={12} sm={2}>
            <DateTimeInput source="date_penalty" label="Data da Infração" fullWidth isRequired />
          </Grid>
          <Grid item xs={12} sm={2}>
            <DateInput source="date_due" label="Data Vencimento" parse={(date) => (date ? date.toISOString() : null)} fullWidth isRequired />
          </Grid>
          <Grid item xs={12} sm={6} />

          <Grid item xs={12} sm={2}>
            <TextInput source="points" label="Pontos" fullWidth />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextInput source="gravity" label="Gravidade" fullWidth />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextInput source="comments" label="Observação" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} />

          <PaymentValueField />
        </Grid>
      </SimpleForm>
    </Edit>
  )
}

export default EnterpriseVehiclePenaltyEdit
