const EnterpriseResourceEnum = (function ()
{
  const resources =
  {
    'enterprise-region': 'regions',
    'enterprise-billing-period': 'billing_periods',
    'enterprise-payment-form': 'payment_forms',
    'enterprise-type-withdrawal': 'types_withdrawal',
    'enterprise-type-invoice': 'types_invoice',
    'enterprise-bank-account': 'bank_accounts',
    'enterprise-client-niche': 'client_niches',
    'enterprise-client-classification': 'client_classifications',
    'enterprise-report-day': 'reports_day',
    'enterprise-report-commission': 'reports_comission',
    'enterprise-vehicle': 'vehicles',
    'enterprise-vehicle-maintenance': 'vehicles_maintenances',
    'enterprise-vehicle-penalty': 'vehicles_penalties',
    'enterprise-type-employee-cost': 'types_employee_cost',
    'enterprise-employee': 'employees',
    'enterprise-employee-cost': 'employee_costs',
    'customer': 'customers',
    'enterprise-client': 'search_clients',
    'client': 'clients',
    'client-prospection': 'clients_prospection',
    'contract': 'contracts',
    'enterprise-installment': 'installments',
    'enterprise-installment-penalty': 'installment_penalties',
    'enterprise-withdrawal': 'withdrawals',
    'enterprise-cash-register': 'cash_registers',
    'enterprise-invoice': 'invoices',
    'enterprise-banking-flow': 'banking_flow',
    'enterprise-report-payment': 'report_payment',
    'enterprise-log': 'logs'
  };

  return {
    get: function (name) {
      return resources[name];
    }
  };
})();

export default EnterpriseResourceEnum;
