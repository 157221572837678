import {
  Create,
  SimpleForm,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  FileInput,
  FileField
} from 'react-admin';
import { Grid } from '@mui/material';
import * as Yup from "yup";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { leastOneOption, validDate, number, hasValue, file } from "../../../utils/schemas";

const Schema = Yup.object({
  enterprise_type_invoice_id: leastOneOption(),
  number_repetitions: leastOneOption(),
  value: number(),
  date_issue: validDate(),
  date_due: validDate(),
  notes: Yup.array().of(
    Yup.object().shape(
    {
      note: hasValue()
    })
  ),
  enterprise_invoice_files: Yup.array().of(
    Yup.object().shape(
    {
      file_name: hasValue(),
      file: file()
    })
  )
});

const EnterpriseInvoiceCreate = () => (
  <Create
    title="Nova Conta a Pagar"
    redirect="list">
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={<ToolbarSave />}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={3}>
          <ReferenceInput source="enterprise_type_invoice_id" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/types-invoice">
            <SelectInput label="Tipo" optionText="name" optionValue="id" fullWidth isRequired />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectInput source="number_repetitions" label="Intervalo" choices={[
              { id: 0, name: 'Não repetir' }, { id: 1, name: '1 mês' },
              { id: 2, name: '2 meses' }, { id: 3, name: '3 meses' },
              { id: 4, name: '4 meses' }, { id: 5, name: '5 meses' },
              { id: 6, name: '6 meses' }, { id: 7, name: '7 meses' },
              { id: 8, name: '8 meses' }, { id: 9, name: '9 meses' },
              { id: 10, name: '10 meses' }, { id: 11, name: '11 meses' },
              { id: 12, name: '12 meses' } ]} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <TextInput source="bar_code" label="Código de Barras" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={2}>
          <NumberInput source="value" label="Valor" step={1} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={2}>
          <DateInput source="date_issue" label="Data Emissão" parse={(date) => (date ? date.toISOString() : null)} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={2}>
          <DateInput source="date_due" label="Data Vencimento" parse={(date) => (date ? date.toISOString() : null)} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={3}>
          <TextInput source="description" label="Descrição" fullWidth />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextInput source="recipient" label="Beneficiário" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <ArrayInput label="Observação(ões)" source="notes">
            <SimpleFormIterator disableReordering fullWidth>
              <TextInput source="note" label="Observação" fullWidth isRequired />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <ArrayInput label="Arquivo(s)" source="enterprise_invoice_files">
            <SimpleFormIterator disableReordering fullWidth>
              <TextInput source="file_name" label="Nome" fullWidth isRequired />
              <FileInput source="file" label="Arquivo" isRequired>
                <FileField source="src" title="title" />
              </FileInput>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </SimpleForm>
  </Create>
);

export default EnterpriseInvoiceCreate;
