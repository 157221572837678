import { SearchInput, ReferenceInput, SelectArrayInput, DateInput } from "react-admin";
import { Chip } from "@mui/material";

const FilterEnterpriseClientProspection = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput
    label="Regiões"
    source="regions"
    reference="setup/enterprise/regions">
    <SelectArrayInput label="Regiões" optionText="name" optionValue="id" />
  </ReferenceInput>,
  <ReferenceInput
    label="Nichos"
    source="niches"
    reference="setup/enterprise/client/niches">
    <SelectArrayInput label="Nichos" optionText="name" optionValue="id" />
  </ReferenceInput>,
  <ReferenceInput
    label="Classificações"
    source="classifications"
    reference="setup/enterprise/client/classifications">
    <SelectArrayInput label="Classificações" optionText="name" optionValue="id" />
  </ReferenceInput>,
  <DateInput source="date_init_created_at" label="Data Criação Inicial" parse={(date) => (date ? date.toISOString() : null)} />,
  <DateInput source="date_end_created_at" label="Data Criação Final" parse={(date) => (date ? date.toISOString() : null)} />,
  <Chip source="activated" label="Ativos" defaultValue={true} />,
  <Chip source="disabled" label="Desativados" defaultValue={true} />
];

export default FilterEnterpriseClientProspection;
