import { SearchInput, ReferenceInput, SelectInput, AutocompleteInput, SelectArrayInput, DateInput, NumberInput } from "react-admin";
import { Chip } from "@mui/material";

const FilterEnterpriseContract = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Status do Contrato" source="status_contract_type" reference="setup/enterprise/status-contract">
    <SelectInput
      label="Status do Contrato"
      optionText="name"
      optionValue="type"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <ReferenceInput label="Cliente" source="client_id" reference="setup/enterprise/clients">
    <AutocompleteInput
      label="Cliente"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      limitChoicesToValue={true}
      shouldRenderSuggestions={(val) => { return val ? val.trim().length > 1 : false }}
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <ReferenceInput
    label="Regiões"
    source="regions"
    reference="setup/enterprise/regions">
    <SelectArrayInput label="Regiões" optionText="name" optionValue="id" />
  </ReferenceInput>,
  <ReferenceInput label="Perído de Pagamento" source="enterprise_billing_period_id" reference="setup/enterprise/billing-periods">
    <SelectInput
      label="Perído de Pagamento"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <Chip source="finalized_with_value_to_receive" label="Finalizado com valor a receber" defaultValue={true} />,
  <DateInput source="date_init_created_at" label="Data de Criação Inicial" parse={(date) => (date ? date.toISOString() : null)} />,
  <DateInput source="date_end_created_at" label="Data de Criação Final" parse={(date) => (date ? date.toISOString() : null)} />,
  <NumberInput source="value_init" label="Valor Inicial" />,
  <NumberInput source="value_end" label="Valor Final" />,
  <DateInput source="date_init" label="Data Inicial" parse={(date) => (date ? date.toISOString() : null)} />,
  <DateInput source="date_end" label="Data Final" parse={(date) => (date ? date.toISOString() : null)} />
];

export default FilterEnterpriseContract;
