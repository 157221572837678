import { usePermissions } from "react-admin";
import { Settings, Cancel } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ShowClientProspectionButton from "../buttons/show-client-prospection-button";
import DialogClientAddFile from "./dialog-client-add-file";
import EditClientButton from "../buttons/edit-client-button";
import DialogConvertClientProspection from "./dialog-convert-client-prospection";
import DialogClientProspectionAbsent from "./dialog-client-prospection-absent";
import DialogDeleteClient from "./dialog-delete-client";
import useToggle from "../../ra/hook/useToggle";
import WrapperDirection from "../wrapper-direction";
import config from "../../config";

function DialogClientActions({ isEnterprise = true, isClient = true })
{
  const { permissions } = usePermissions();
  const { on, toggle } = useToggle();

  const handleClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleCloseClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  return (
    <>
      {
        isEnterprise ?
        <Button startIcon={<Settings />} color="success" onClick={handleClick}>
          Ações
        </Button> :
        <Button color="success" onClick={handleClick} >
          <Settings />
        </Button>
      }

      <Dialog
        open={on}
        onClose={handleCloseClick}
        aria-label="Ações">
        <DialogTitle>Selecione uma opção</DialogTitle>
        <DialogContent>
          <WrapperDirection direction="column">
            <ShowClientProspectionButton isEnterprise={isEnterprise} isClient={isClient} />
            <DialogClientAddFile isEnterprise={isEnterprise} isClient={isClient} />
            {
              (!isEnterprise ||
              ((isClient && permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.clients.edit))) ||
               (!isClient && permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.clients_prospection.edit))))) &&
              <EditClientButton isEnterprise={isEnterprise} isClient={isClient} />
            }
            {
              permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.clients_prospection.convert)) &&
              <DialogConvertClientProspection isEnterprise={isEnterprise} isClient={isClient} />
            }
            <DialogClientProspectionAbsent isEnterprise={isEnterprise} isClient={isClient} />
            {
              (!isEnterprise ||
              ((isClient && permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.clients.delete))) ||
               (!isClient && permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.clients_prospection.delete))))) &&
              <DialogDeleteClient isEnterprise={isEnterprise} isClient={isClient} />
            }
          </WrapperDirection>
        </DialogContent>
        <DialogActions>
          <Button startIcon={<Cancel />} color="error" onClick={handleCloseClick}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogClientActions;
