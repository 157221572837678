import { SearchInput, ReferenceInput, SelectInput, AutocompleteInput, DateInput, maxValue } from "react-admin";
import { Chip } from "@mui/material";
import moment from "moment";

const FilterCustomerInstallment = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Status da Parcela" source="status_installment_type" reference="setup/customer/status-installment">
    <SelectInput
      label="Status da Parcela"
      optionText="name"
      optionValue="type"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <ReferenceInput label="Cliente" source="client_id" reference="setup/customer/clients">
    <AutocompleteInput
      label="Cliente"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      limitChoicesToValue={true}
      shouldRenderSuggestions={(val) => { return val ? val.trim().length > 1 : false }}
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <Chip source="show_absent" label="Ver Ausentes" defaultValue={true} />,
  <DateInput source="date_billing" label="Data de Início Cobrança" parse={(date) => (date ? date.toISOString() : null)} validate={maxValue(moment().format("YYYY-MM-DD"))} />,
  <DateInput source="date_end_billing" label="Data Final Cobrança" parse={(date) => (date ? date.toISOString() : null)} validate={maxValue(moment().format("YYYY-MM-DD"))} />
];

export default FilterCustomerInstallment;
