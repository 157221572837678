import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  DateInput,
  NumberInput
} from 'react-admin';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import Title from "../../../ra/edit/title";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { leastOneOption, hasValue, number, validDate } from "../../../utils/schemas";

const Schema = Yup.object({
  enterprise_vehicle_id: leastOneOption(),
  name: hasValue(),
  value: number(),
  date_maintenance: validDate()
});

const EnterpriseVehicleMaintenanceEdit = () => (
  <Edit
    title={ <Title subtitle="Editar Manutenção" source="name" /> }
    redirect="list"
    mutationMode="pessimistic">
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={ <ToolbarSave /> }>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={2}>
          <TextInput source="id" label="Id" fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={10} />

        <Grid item xs={12} sm={6}>
          <ReferenceInput source="enterprise_vehicle_id" sort={{ field: 'name', order: 'ASC' }} reference="setup/enterprise/vehicles">
            <SelectInput label="Veículo" optionText="name" optionValue="id" fullWidth isRequired />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <TextInput source="name" label="Nome" fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={3}>
          <NumberInput source="value" label="Valor" step={1} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DateInput source="date_maintenance" label="Data Manutenção" parse={(date) => (date ? date.toISOString() : null)} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
    </SimpleForm>
  </Edit>
)

export default EnterpriseVehicleMaintenanceEdit
