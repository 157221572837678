import {
  Create,
  SimpleForm,
  DateInput,
  ReferenceInput,
  SelectArrayInput,
  FormDataConsumer
} from 'react-admin';
import { Grid } from "@mui/material";
import * as Yup from "yup";
import { Save as ToolbarSave } from "../../../ra/toolbar";
import CashRegister from "../../../components/cash-register";
import { yupResolver } from "@hookform/resolvers/yup";
import { validDate, array, minNumber, hasValue, file } from "../../../utils/schemas";

const Schema = Yup.object({
  date: validDate(),
  enterprise_payments_form: array(),
  value_deposited: minNumber(0),
  cash_register_files: Yup.array().of(
    Yup.object().shape(
    {
      file_name: hasValue(),
      file: file()
    })
  )
});

const CashRegisterCreate = () => (
  <Create
    title="Novo Fechamento de Caixa"
    redirect="list">
    <SimpleForm
      noValidate
      resolver={yupResolver(Schema)}
      toolbar={<ToolbarSave />}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={2}>
          <DateInput source="date" label="Data" parse={(date) => (date ? date.toISOString() : null)} fullWidth isRequired />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ReferenceInput source="enterprise_payments_form" sort={{ field: 'name', order: 'ASC' }} reference={"setup/customer/payment-forms"}>
            <SelectArrayInput label="Forma(s) de Pagamento" optionText="name" optionValue="id" fullWidth isRequired />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} />

        <FormDataConsumer>
        {
          ({ formData }) =>
          formData &&
          formData.date &&
          formData.enterprise_payments_form &&
          formData.enterprise_payments_form.length > 0 ? <CashRegister /> : null
        }
        </FormDataConsumer>
      </Grid>
    </SimpleForm>
  </Create>
);

export default CashRegisterCreate;
