var env = process.env.REACT_APP_NODE_ENV || "development";

var config =
{
  development:
  {
    api:
    {
      api_key: "7dcbe1e9b6a5bc2da5104aeaef1796d7",
      url: "https://api-dev.gestor-dashboard.com.br"
    },
    local_storage:
    {
      user: "user_admin_dashboard",
      auth: "auth_admin_dashboard",
      location: "current_location",
      date: "current_date",
      filter_chart_financial: "filter_chart_financial",
      filter_chart_clients: "filter_chart_clients",
      filter_chart_clients_per_region: "filter_chart_clients_per_region",
      filter_chart_clients_per_niche: "filter_chart_clients_per_niche",
      filter_chart_contracts: "filter_chart_contracts",
      filter_chart_contracts_per_billing_period: "filter_chart_contracts_per_billing_period",
      filter_chart_withdrawals_per_type: "filter_chart_withdrawals_per_type",
      filter_chart_withdrawals_per_customer: "filter_chart_withdrawals_per_customer",
      filter_chart_invoices_per_type: "filter_chart_invoices_per_type"
    },
    jwt:
    {
      key: "01d5e801f1fedd8e893462d7ff92022f"
    },
    google_places:
    {
      api_key: "AIzaSyCgDCh5bVNHm9fHdA5BCIpQk80FEVLH18c"
    },
    type_item_route:
    {
      client_prospection: 0,
      installment: 1
    },
    type_item_contract:
    {
      installment: 0,
      penalty: 1
    },
    type_banking_flow:
    {
      withdrawal: 0,
      deposit: 1
    },
    status_contract:
    {
      in_day: 0,
      overdue: 1,
      finished: 2,
      canceled: 3,
      loss: 4
    },
    status_installment:
    {
      in_day: 0,
      overdue: 1,
      pay: 2,
      pay_partial: 3,
      canceled: 4,
      extended: 5,
      loss: 6
    },
    status_cash_register:
    {
      waiting_check: 0,
      approved: 1,
      reproved: 2
    },
    status_invoice:
    {
      in_day: 0,
      overdue: 1,
      pay: 2,
      canceled: 3
    },
    status_penalty:
    {
      in_day: 0,
      overdue: 1,
      pay: 2,
      canceled: 3
    },
    export:
    {
      max_results: 10000
    },
    chart:
    {
      max_columns: 10,
      max_months: 12,
      min_months: 1
    },
    type_user:
    {
      admin: 'admin',
      enterprise: 'user-enterprise',
      customer: 'customer'
    },
    permissions:
    {
      dashboard:
      {
        general: "2beb8b0470336e6095d9aacfeef6f076",
        monthly: "76fccfce3f261b396d1bdd08571051e0"
      },
      charts:
      {
        financial: "0f76b8b7bcd98213157b6c4e4e84dc9e",
        clients: "3cf4e0941d13d51edadf23a833d786de",
        clients_per_region: "2e55c0cab6fbefe4c14667ed17254ef5",
        clients_per_niche: "fac792a3ca2a2d11bd3f7d2ab286703a",
        contracts: "eb11fd2eb432ba1e71c7d8e2fb99a57d",
        contracts_per_period: "d74e2b3cdc9895eef6c7ddd675455928",
        withdrawals_per_type: "8eebfe84d5386bb065334bea1029531a",
        withdrawals_per_customer: "df79bac40f830745a30a496fce5980cb",
        invoices_per_type: "697b629a9387c7ffde2307c9f71c447b"
      },
      regions:
      {
        list: "cddab82d364b47e09fa4a1f24d6221ed",
        show: "8d0bf6a6d7e4c836d3de5abc5d2c0c4b",
        create: "6cd2cd7d3f94122bf2554c8801674e70",
        edit: "7eca9d0b180f6ccfea1ae221c486edd6",
        export: "6cffa73a464cc13a852aae2d31d5b4c5"
      },
      billing_periods:
      {
        list: "3aefc886661b4728e6900ec69be38be4",
        show: "b220c2e6138fdb76fd46f486e84b2a7e",
        create: "f5b4bb60ec8d882aec5f5bcdb8e87cb0",
        edit: "a7dae1b7e96325826f72935543a70166",
        export: "ce0bb7297b6f735a2773e503f9f62082"
      },
      payment_forms:
      {
        list: "303f592bd8180174011d7c74140a66f2",
        show: "78040a0fc1c03e24b41f006600e391f1",
        create: "46ca99d8066886e5f8299e7ba5127d65",
        edit: "6ef7bd1195985559d009c62656177050",
        export: "d761a87bf8c8cbedecadac04cd6d6606"
      },
      types_withdrawal:
      {
        list: "802be6225514024a51b6f65984f95fde",
        show: "3db3fadb380094017901797890cfced8",
        create: "443a6b7ab62f939c31acdcbb3f5b6362",
        edit: "dbc72ec26942da2775fd61b593159117",
        export: "4a81c0c0043c2058b7b0afb0bdf8cd33"
      },
      types_invoice:
      {
        list: "c546e3466b4b67250163ab0efab4d4cd",
        show: "9a510ac96eb589645a4cc77416930118",
        create: "07738e133f6b7ba3e3323d539a3461e6",
        edit: "a8807951f5233139df7852524679a2f4",
        export: "8af51f4ffcaa03ae137cf1ec69aeb84d"
      },
      bank_accounts:
      {
        list: "ca60da3a8ae7fcd39a9691c235665650",
        show: "3f974af971c114291e1ea55537a031bf",
        create: "7146061d3b2292cc26ce8b751c713045",
        edit: "d059eb2d76ef35386ffda805ac4ba597",
        export: "3e99df6f4961837c095ca469e297973b"
      },
      client_niches:
      {
        list: "6d484d35779ba390497ecd78b160174e",
        show: "ca081033717c8df2a11f1544fffed8a8",
        create: "cf133e083b6a955aa8ffa157e4231e55",
        edit: "aea903f27b4f64d235230786b7b1f23d",
        export: "c8e0d606d21c5f9068cbaf2eafc86532",
        show_value_invested: "987152d630c941ab7825e7ba9ea6afcd"
      },
      client_classifications:
      {
        list: "59899ad808aa5223e7ea72cc142d517e",
        show: "9892ba96d7e77b59505acdd4721b26a8",
        create: "ca9b1e87043189f91f3a35c9c51fef62",
        edit: "f82737b6ab0dc40d7451daa2165c43f5",
        export: "4534e92ef6517489388a6453843b9fff"
      },
      reports_day:
      {
        list: "cb783cb6d2d74d5959877e20148102a9",
        show: "7bf0668fa2638e7cee9cc528cd03d1fc",
        export: "2cde6d84cdc4bc7599ca36c406b7f34e"
      },
      reports_comission:
      {
        list: "7341d6cea0f3e25d28e42213286d264d",
        show: "af7969315e3ef8d72100764bb1c9ced3",
        create: "9481f707b4e13fa5d972dae9c16d7eac",
        export: "97dac670a6029d002bc59991cd364976",
        delete: "5f2702474530d58106e1d81e43b1461b"
      },
      vehicles:
      {
        list: "9f2dd4380e9db878a317ee1079ba1f15",
        show: "0e456592d7a4af8d25860523d4f72512",
        create: "f2e534a9284f51cdef4b87887238ff8a",
        edit: "d0315d5579dc00dffaf2164eb2563d07",
        export: "a4d7826d25c8b25ef299a2506cd0b9cb",
        delete: "ad94079235d49e46551e716ecdf7b99e"
      },
      vehicles_maintenances:
      {
        list: "7224f032572acab75d677ba600a909ae",
        show: "4d9ab2bf6a0779c55d847175338e1603",
        create: "71cee57fa3e73ff24e11a4c93e92f357",
        edit: "210b62721bc6b6b893ba73e37b135060",
        export: "e0f0488ab7b71b7ca7d467bdf1dc1230",
        delete: "307e2e443f9a8fcfce633d89cccf1081"
      },
      vehicles_penalties:
      {
        list: "5b4dc4c1252111454710addefbaba831",
        show: "f7de9ffc93756b0d7dcf4067619e770d",
        create: "f5d504add3d90eeb699ded42ac96c054",
        edit: "ca01ced5d891dff7e35da68d00dde4a5",
        export: "e0f02be518a1cbd85d781d010edf8f40",
        cancel: "5121c44547e6b9a3f75d6cf5b5fde2e7"
      },
      types_employee_cost:
      {
        list: "4f740800089c3dcda7c7048308fd935d",
        show: "f7de9ffc93756b0d7dcf4067619e770d",
        create: "162a26a65c5b60d54d0580c238d1386e",
        edit: "1ba165384ed481d82d8aa98da46c2130",
        export: "d15afd8d402538dba27145b73e73eba3"
      },
      employees:
      {
        list: "6df7032d182cab50bef7c520d4c327bc",
        show: "f0e12f3c6cd874061fe53d8a3caf2589",
        create: "d83ae08aafd3dc71cf3e86863e924d67",
        edit: "afe26b624d4221ade73e081c816fc055",
        export: "547419295dbbcd192e646f2cf53b8e91"
      },
      employee_costs:
      {
        list: "71b8abc9215abded9a2a8e945a85b530",
        show: "fb23f42a73c19e3000e8dd7557ed3bd7",
        create: "9ac8edebf2f18e218fa5f7552d97e416",
        edit: "1fcb08b6eed3e70a78e8eeeb827bacd1",
        export: "fb8a68316af98ec36460bf20defb7015",
        delete: "51c83387423a3536fc0d1b12fe1c970d"
      },
      customers:
      {
        list: "ca4aa0f3ec21b10dd15d89943e94e29d",
        show: "5875aebb02a331d05471072518ce1ee6",
        create: "5ce24ede44bfa0cc6c8ece87113a4c44",
        edit: "62366804e841dff4d629797e7ab74733",
        export: "b8cc8e7b857d08c7a5577061c2e7561d"
      },
      search_clients:
      {
        list: "e1ebaab588fb97cf59bc223670739ad4"
      },
      clients:
      {
        list: "780381ef8abe93c6669752b66c8d2d3d",
        show: "5e2e6099f8daa3653492e388e84ac16f",
        create: "f146c06a021b3b7a86bbc434fae654c7",
        edit: "73e0cb993462b90cd99adf0f7e91b89c",
        export: "1ac7e7f29e0a8551d8b44ec4eb136054",
        delete: "efc588a042286e053048c7dbb4fd4350"
      },
      clients_prospection:
      {
        list: "ab1f53e9a0877e741da0be7298d7a70c",
        show: "70945befd71a130133a7b3887e22ae89",
        create: "a1007ae8fcb89c351d39c5bd7ab3990e",
        edit: "a4aaf71ea7d4357e73f0c2f970edf378",
        export: "efdd8be5730f2a95f7c0d3974a825fdf",
        delete: "0b01c632bc5615b30468089f31805afc",
        convert: "aacd00211017432233f9c66adf0d5766"
      },
      contracts:
      {
        list: "ee63aef1965f60f016ab1506fbad776e",
        show: "de2731ef44e51ff84a179e0ad2454a21",
        create: "14af299b7573247ba2e06359203f42d6",
        export: "6f1481c86f0a36b70fabd9bde96b59df",
        cancel: "5270c7988f38ef455f1f8181a85e3cd2",
        loss: "da501e553b6820a2c1c01676071bd988",
        reactivate: "ca74ff3f123ecee8e5c05dc35c31e8c3",
        show_total_values: "804ef7559dff43143822e35a2ed7e0b2"
      },
      installments:
      {
        list: "9054a69b6e26020875dcd3056e368363",
        show: "049c0c13a041a4f33a8d6d1376c16afe",
        create: "2794565e522001ddd311cb79c9593f7e",
        edit: "b0d2bc36261a91c4ba343c68878e1a56",
        export: "ac0cd0a462721c1a1c3319292160bdc8",
        cancel: "9e0155bc8c758dd4268786cd9b3aa35f",
        edit_dates_billing_and_payment: "e80698da7a71012842f04fbc6baa7b6c"
      },
      installment_penalties:
      {
        list: "5e8a78970a16714b844130c9ababde48",
        show: "730c1d712fb1686f8b51fe13142c69a7",
        edit: "09bfb24ee27b977accef792b27b8778b",
        export: "6b97ba602e997a0178f6ff7e07c0fc77"
      },
      withdrawals:
      {
        list: "c6686fcb69bdb6163bb4d34ea6fbfa40",
        show: "611bde3a6456a83b4241dc8b8ef364f7",
        create: "d739bbb1cd504abda8a41ca17ee9856c",
        edit: "a364d1b24399abba58d307d2c14f269c",
        export: "0a9e78f2c8288fdceddead6a9d734de4"
      },
      cash_registers:
      {
        list: "bd7c04d05f50c777c58748fbb8024fe9",
        show: "3e08c2d60f764541c1fe83da650347d7",
        export: "ef2d2477bf16736c31fdd98fdbab34e0",
        approve_reprove: "f9d747170d6d08b510a89ca206ca295d"
      },
      invoices:
      {
        list: "f5ace84e38c2fe0047ebfa48de8d5e4e",
        show: "cb3b645ae3c5eb3b7023305cd19a9dc0",
        create: "473ef3b0c1ac8072eb6e4c474f26b41f",
        edit: "3088f557c48ac3f1d8312dabc479dcdc",
        export: "d307ec503ae3be48094f7e132001fba5",
        cancel: "20765e1536647a8d7a9c15d6acc90dc5"
      },
      banking_flow:
      {
        list: "9b1a53c0905743fa5d04fa3965e65020",
        export: "33879693a58b6cddc5c3e49b52a06ae3"
      },
      report_payment:
      {
        key: "265e051fd12f3f81abb3588645f3fa0c",
        list: "9490539b73c1ab8b7f4c8c809e4b3ae3",
        filter_per_customer: "00a55e1fc2aa5497a74735001e6a6541",
        filter_per_date: "e18a2c4dba1e3bc3fe9087aaf417b2eb"
      },
      installment_actions:
      {
        key: "df2f8f5dcf1399ef8114c7f22e6387b1",
        full_receive: "79fb30d056ef3ffd8283401b67197bbd",
        partial_receive: "a33023e17a995eef40d8c4adb3adab7b",
        extended: "0baabe4cb850900ce014801b6a9d4b8e",
        penalty: "08e7f1f8bb2a9e8e99ec7052a5ffad97"
      },
      logs:
      {
        list: "469e9d7605df22f21d85ffada8516a71",
        show: "015ded67b6803a86e09963477dde5c24",
        export: "b97abfa51ff522c9e856b25b6a4663df"
      }
    }
  },
  production:
  {
    api:
    {
      api_key: "7dcbe1e9b6a5bc2da5104aeaef1796d7",
      url: "https://api.gestor-dashboard.com.br"
    },
    local_storage:
    {
      user: "user_admin_dashboard",
      auth: "auth_admin_dashboard",
      location: "current_location",
      date: "current_date",
      filter_chart_financial: "filter_chart_financial",
      filter_chart_clients: "filter_chart_clients",
      filter_chart_clients_per_region: "filter_chart_clients_per_region",
      filter_chart_clients_per_niche: "filter_chart_clients_per_niche",
      filter_chart_contracts: "filter_chart_contracts",
      filter_chart_contracts_per_billing_period: "filter_chart_contracts_per_billing_period",
      filter_chart_withdrawals_per_type: "filter_chart_withdrawals_per_type",
      filter_chart_withdrawals_per_customer: "filter_chart_withdrawals_per_customer",
      filter_chart_invoices_per_type: "filter_chart_invoices_per_type"
    },
    jwt:
    {
      key: "68039574702a2ab87fa62f338b6d7965"
    },
    google_places:
    {
      api_key: "AIzaSyCgDCh5bVNHm9fHdA5BCIpQk80FEVLH18c"
    },
    type_item_route:
    {
      client_prospection: 0,
      installment: 1
    },
    type_item_contract:
    {
      installment: 0,
      penalty: 1
    },
    type_banking_flow:
    {
      withdrawal: 0,
      deposit: 1
    },
    status_contract:
    {
      in_day: 0,
      overdue: 1,
      finished: 2,
      canceled: 3,
      loss: 4
    },
    status_installment:
    {
      in_day: 0,
      overdue: 1,
      pay: 2,
      pay_partial: 3,
      canceled: 4,
      extended: 5,
      loss: 6
    },
    status_cash_register:
    {
      waiting_check: 0,
      approved: 1,
      reproved: 2
    },
    status_invoice:
    {
      in_day: 0,
      overdue: 1,
      pay: 2,
      canceled: 3
    },
    status_penalty:
    {
      in_day: 0,
      overdue: 1,
      pay: 2,
      canceled: 3
    },
    export:
    {
      max_results: 10000
    },
    chart:
    {
      max_columns: 10,
      max_months: 12,
      min_months: 2
    },
    type_user:
    {
      admin: 'admin',
      enterprise: 'user-enterprise',
      customer: 'customer'
    },
    permissions:
    {
      dashboard:
      {
        general: "2beb8b0470336e6095d9aacfeef6f076",
        monthly: "76fccfce3f261b396d1bdd08571051e0"
      },
      charts:
      {
        financial: "0f76b8b7bcd98213157b6c4e4e84dc9e",
        clients: "3cf4e0941d13d51edadf23a833d786de",
        clients_per_region: "2e55c0cab6fbefe4c14667ed17254ef5",
        clients_per_niche: "fac792a3ca2a2d11bd3f7d2ab286703a",
        contracts: "eb11fd2eb432ba1e71c7d8e2fb99a57d",
        contracts_per_period: "d74e2b3cdc9895eef6c7ddd675455928",
        withdrawals_per_type: "8eebfe84d5386bb065334bea1029531a",
        withdrawals_per_customer: "df79bac40f830745a30a496fce5980cb",
        invoices_per_type: "697b629a9387c7ffde2307c9f71c447b"
      },
      regions:
      {
        list: "cddab82d364b47e09fa4a1f24d6221ed",
        show: "8d0bf6a6d7e4c836d3de5abc5d2c0c4b",
        create: "6cd2cd7d3f94122bf2554c8801674e70",
        edit: "7eca9d0b180f6ccfea1ae221c486edd6",
        export: "6cffa73a464cc13a852aae2d31d5b4c5"
      },
      billing_periods:
      {
        list: "3aefc886661b4728e6900ec69be38be4",
        show: "b220c2e6138fdb76fd46f486e84b2a7e",
        create: "f5b4bb60ec8d882aec5f5bcdb8e87cb0",
        edit: "a7dae1b7e96325826f72935543a70166",
        export: "ce0bb7297b6f735a2773e503f9f62082"
      },
      payment_forms:
      {
        list: "303f592bd8180174011d7c74140a66f2",
        show: "78040a0fc1c03e24b41f006600e391f1",
        create: "46ca99d8066886e5f8299e7ba5127d65",
        edit: "6ef7bd1195985559d009c62656177050",
        export: "d761a87bf8c8cbedecadac04cd6d6606"
      },
      types_withdrawal:
      {
        list: "802be6225514024a51b6f65984f95fde",
        show: "3db3fadb380094017901797890cfced8",
        create: "443a6b7ab62f939c31acdcbb3f5b6362",
        edit: "dbc72ec26942da2775fd61b593159117",
        export: "4a81c0c0043c2058b7b0afb0bdf8cd33"
      },
      types_invoice:
      {
        list: "c546e3466b4b67250163ab0efab4d4cd",
        show: "9a510ac96eb589645a4cc77416930118",
        create: "07738e133f6b7ba3e3323d539a3461e6",
        edit: "a8807951f5233139df7852524679a2f4",
        export: "8af51f4ffcaa03ae137cf1ec69aeb84d"
      },
      bank_accounts:
      {
        list: "ca60da3a8ae7fcd39a9691c235665650",
        show: "3f974af971c114291e1ea55537a031bf",
        create: "7146061d3b2292cc26ce8b751c713045",
        edit: "d059eb2d76ef35386ffda805ac4ba597",
        export: "3e99df6f4961837c095ca469e297973b"
      },
      client_niches:
      {
        list: "6d484d35779ba390497ecd78b160174e",
        show: "ca081033717c8df2a11f1544fffed8a8",
        create: "cf133e083b6a955aa8ffa157e4231e55",
        edit: "aea903f27b4f64d235230786b7b1f23d",
        export: "c8e0d606d21c5f9068cbaf2eafc86532",
        show_value_invested: "987152d630c941ab7825e7ba9ea6afcd"
      },
      client_classifications:
      {
        list: "59899ad808aa5223e7ea72cc142d517e",
        show: "9892ba96d7e77b59505acdd4721b26a8",
        create: "ca9b1e87043189f91f3a35c9c51fef62",
        edit: "f82737b6ab0dc40d7451daa2165c43f5",
        export: "4534e92ef6517489388a6453843b9fff"
      },
      reports_day:
      {
        list: "cb783cb6d2d74d5959877e20148102a9",
        show: "7bf0668fa2638e7cee9cc528cd03d1fc",
        export: "2cde6d84cdc4bc7599ca36c406b7f34e"
      },
      reports_comission:
      {
        list: "7341d6cea0f3e25d28e42213286d264d",
        show: "af7969315e3ef8d72100764bb1c9ced3",
        create: "9481f707b4e13fa5d972dae9c16d7eac",
        export: "97dac670a6029d002bc59991cd364976",
        delete: "5f2702474530d58106e1d81e43b1461b"
      },
      vehicles:
      {
        list: "9f2dd4380e9db878a317ee1079ba1f15",
        show: "0e456592d7a4af8d25860523d4f72512",
        create: "f2e534a9284f51cdef4b87887238ff8a",
        edit: "d0315d5579dc00dffaf2164eb2563d07",
        export: "a4d7826d25c8b25ef299a2506cd0b9cb",
        delete: "ad94079235d49e46551e716ecdf7b99e"
      },
      vehicles_maintenances:
      {
        list: "7224f032572acab75d677ba600a909ae",
        show: "4d9ab2bf6a0779c55d847175338e1603",
        create: "71cee57fa3e73ff24e11a4c93e92f357",
        edit: "210b62721bc6b6b893ba73e37b135060",
        export: "e0f0488ab7b71b7ca7d467bdf1dc1230",
        delete: "307e2e443f9a8fcfce633d89cccf1081"
      },
      vehicles_penalties:
      {
        list: "5b4dc4c1252111454710addefbaba831",
        show: "f7de9ffc93756b0d7dcf4067619e770d",
        create: "f5d504add3d90eeb699ded42ac96c054",
        edit: "ca01ced5d891dff7e35da68d00dde4a5",
        export: "e0f02be518a1cbd85d781d010edf8f40",
        cancel: "5121c44547e6b9a3f75d6cf5b5fde2e7"
      },
      types_employee_cost:
      {
        list: "4f740800089c3dcda7c7048308fd935d",
        show: "e65aa995c10f2ba2ec0b6a1731d2e3a5",
        create: "162a26a65c5b60d54d0580c238d1386e",
        edit: "1ba165384ed481d82d8aa98da46c2130",
        export: "d15afd8d402538dba27145b73e73eba3"
      },
      employees:
      {
        list: "6df7032d182cab50bef7c520d4c327bc",
        show: "f0e12f3c6cd874061fe53d8a3caf2589",
        create: "d83ae08aafd3dc71cf3e86863e924d67",
        edit: "afe26b624d4221ade73e081c816fc055",
        export: "547419295dbbcd192e646f2cf53b8e91"
      },
      employee_costs:
      {
        list: "71b8abc9215abded9a2a8e945a85b530",
        show: "fb23f42a73c19e3000e8dd7557ed3bd7",
        create: "9ac8edebf2f18e218fa5f7552d97e416",
        edit: "1fcb08b6eed3e70a78e8eeeb827bacd1",
        export: "fb8a68316af98ec36460bf20defb7015",
        delete: "51c83387423a3536fc0d1b12fe1c970d"
      },
      customers:
      {
        list: "ca4aa0f3ec21b10dd15d89943e94e29d",
        show: "5875aebb02a331d05471072518ce1ee6",
        create: "5ce24ede44bfa0cc6c8ece87113a4c44",
        edit: "62366804e841dff4d629797e7ab74733",
        export: "b8cc8e7b857d08c7a5577061c2e7561d"
      },
      search_clients:
      {
        list: "e1ebaab588fb97cf59bc223670739ad4"
      },
      clients:
      {
        list: "780381ef8abe93c6669752b66c8d2d3d",
        show: "5e2e6099f8daa3653492e388e84ac16f",
        create: "f146c06a021b3b7a86bbc434fae654c7",
        edit: "73e0cb993462b90cd99adf0f7e91b89c",
        export: "1ac7e7f29e0a8551d8b44ec4eb136054",
        delete: "efc588a042286e053048c7dbb4fd4350"
      },
      clients_prospection:
      {
        list: "ab1f53e9a0877e741da0be7298d7a70c",
        show: "70945befd71a130133a7b3887e22ae89",
        create: "a1007ae8fcb89c351d39c5bd7ab3990e",
        edit: "a4aaf71ea7d4357e73f0c2f970edf378",
        export: "efdd8be5730f2a95f7c0d3974a825fdf",
        delete: "0b01c632bc5615b30468089f31805afc",
        convert: "aacd00211017432233f9c66adf0d5766"
      },
      contracts:
      {
        list: "ee63aef1965f60f016ab1506fbad776e",
        show: "de2731ef44e51ff84a179e0ad2454a21",
        create: "14af299b7573247ba2e06359203f42d6",
        export: "6f1481c86f0a36b70fabd9bde96b59df",
        cancel: "5270c7988f38ef455f1f8181a85e3cd2",
        loss: "da501e553b6820a2c1c01676071bd988",
        reactivate: "ca74ff3f123ecee8e5c05dc35c31e8c3",
        show_total_values: "804ef7559dff43143822e35a2ed7e0b2"
      },
      installments:
      {
        list: "9054a69b6e26020875dcd3056e368363",
        show: "049c0c13a041a4f33a8d6d1376c16afe",
        create: "2794565e522001ddd311cb79c9593f7e",
        edit: "b0d2bc36261a91c4ba343c68878e1a56",
        export: "ac0cd0a462721c1a1c3319292160bdc8",
        cancel: "9e0155bc8c758dd4268786cd9b3aa35f",
        edit_dates_billing_and_payment: "e80698da7a71012842f04fbc6baa7b6c"
      },
      installment_penalties:
      {
        list: "5e8a78970a16714b844130c9ababde48",
        show: "730c1d712fb1686f8b51fe13142c69a7",
        edit: "09bfb24ee27b977accef792b27b8778b",
        export: "6b97ba602e997a0178f6ff7e07c0fc77"
      },
      withdrawals:
      {
        list: "c6686fcb69bdb6163bb4d34ea6fbfa40",
        show: "611bde3a6456a83b4241dc8b8ef364f7",
        create: "d739bbb1cd504abda8a41ca17ee9856c",
        edit: "a364d1b24399abba58d307d2c14f269c",
        export: "0a9e78f2c8288fdceddead6a9d734de4"
      },
      cash_registers:
      {
        list: "bd7c04d05f50c777c58748fbb8024fe9",
        show: "3e08c2d60f764541c1fe83da650347d7",
        export: "ef2d2477bf16736c31fdd98fdbab34e0",
        approve_reprove: "f9d747170d6d08b510a89ca206ca295d"
      },
      invoices:
      {
        list: "f5ace84e38c2fe0047ebfa48de8d5e4e",
        show: "cb3b645ae3c5eb3b7023305cd19a9dc0",
        create: "473ef3b0c1ac8072eb6e4c474f26b41f",
        edit: "3088f557c48ac3f1d8312dabc479dcdc",
        export: "d307ec503ae3be48094f7e132001fba5",
        cancel: "20765e1536647a8d7a9c15d6acc90dc5"
      },
      banking_flow:
      {
        list: "9b1a53c0905743fa5d04fa3965e65020",
        export: "33879693a58b6cddc5c3e49b52a06ae3"
      },
      report_payment:
      {
        key: "265e051fd12f3f81abb3588645f3fa0c",
        list: "9490539b73c1ab8b7f4c8c809e4b3ae3",
        filter_per_customer: "00a55e1fc2aa5497a74735001e6a6541",
        filter_per_date: "e18a2c4dba1e3bc3fe9087aaf417b2eb"
      },
      installment_actions:
      {
        key: "df2f8f5dcf1399ef8114c7f22e6387b1",
        full_receive: "79fb30d056ef3ffd8283401b67197bbd",
        partial_receive: "a33023e17a995eef40d8c4adb3adab7b",
        extended: "0baabe4cb850900ce014801b6a9d4b8e",
        penalty: "08e7f1f8bb2a9e8e99ec7052a5ffad97"
      },
      logs:
      {
        list: "469e9d7605df22f21d85ffada8516a71",
        show: "015ded67b6803a86e09963477dde5c24",
        export: "b97abfa51ff522c9e856b25b6a4663df"
      }
    }
  }
};

module.exports = config[env];
