import { useRecordContext } from "react-admin";
import { Settings, Cancel } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import useToggle from "../../ra/hook/useToggle";
import config from "../../config";
import WrapperDirection from "../wrapper-direction";
import ShowClientProspectionButton from "../buttons/show-client-prospection-button";
import DialogClientAddFile from "./dialog-client-add-file";
import EditClientButton from "../buttons/edit-client-button";
import DialogConvertClientProspection from "./dialog-convert-client-prospection";
import DialogClientProspectionAbsent from "./dialog-client-prospection-absent";
import DialogDeleteClient from "./dialog-delete-client";
import ShowInstallmentButton from '../buttons/show-installment-button';
import EditInstallmentButton from '../buttons/edit-installment-button';
import DialogInstallmentFullReceived from './dialog-installment-full-received';
import DialogInstallmentPartialReceived from './dialog-installment-partial-received';
import DialogInstallmentExtend from './dialog-installment-extend';
import DialogInstallmentPenalty from './dialog-installment-penalty';
import DialogInstallmentNote from './dialog-installment-note';
import DialogInstallmentClientAbsent from './dialog-installment-client-absent';
import DialogCancelInstallment from './dialog-cancel-installment';

function DialogRouteActions({ isEnterprise = true, isClient = true, isMainInstallment = true })
{
  const record = useRecordContext();
  const { on, toggle } = useToggle();

  const handleClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleCloseClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  // CLIENT PROSPECTION
  if (record.type === config.type_item_route.client_prospection)
  {
    return (
      <>
        <Button color="success" onClick={handleClick} >
          <Settings />
        </Button>

        <Dialog
          open={on}
          onClose={handleCloseClick}
          aria-label="Ações">
          <DialogTitle>Selecione uma opção</DialogTitle>
          <DialogContent>
            <WrapperDirection direction="column">
              <ShowClientProspectionButton isEnterprise={isEnterprise} isClient={isClient} />
              <DialogClientAddFile isEnterprise={isEnterprise} isClient={isClient} />
              <EditClientButton isEnterprise={isEnterprise} isClient={isClient} />
              <DialogConvertClientProspection isEnterprise={isEnterprise} isClient={isClient} />
              <DialogClientProspectionAbsent isEnterprise={isEnterprise} isClient={isClient} />
              <DialogDeleteClient isEnterprise={isEnterprise} isClient={isClient} />
            </WrapperDirection>
          </DialogContent>
          <DialogActions>
            <Button startIcon={<Cancel />} color="error" onClick={handleCloseClick}>
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  // INSTALLMENT
  else if (record.type === config.type_item_route.installment)
  {
    return (
      <>
        <Button color="success" onClick={handleClick} >
          <Settings />
        </Button>

        <Dialog
          open={on}
          onClose={handleCloseClick}
          aria-label="Ações">
          <DialogTitle>Selecione uma opção</DialogTitle>
          <DialogContent>
            <WrapperDirection direction="column">
              <ShowInstallmentButton isEnterprise={isEnterprise} isMainInstallment={isMainInstallment} />
              <EditInstallmentButton isEnterprise={isEnterprise} isMainInstallment={isMainInstallment} />
              <DialogInstallmentFullReceived isEnterprise={isEnterprise} isMainInstallment={isMainInstallment} />
              <DialogInstallmentPartialReceived isEnterprise={isEnterprise} isMainInstallment={isMainInstallment} />
              <DialogInstallmentExtend isEnterprise={isEnterprise} isMainInstallment={isMainInstallment} />
              <DialogInstallmentPenalty isEnterprise={isEnterprise} isMainInstallment={isMainInstallment} />
              <DialogInstallmentNote isEnterprise={isEnterprise} isMainInstallment={isMainInstallment} />
              <DialogInstallmentClientAbsent isEnterprise={isEnterprise} isMainInstallment={isMainInstallment} />
              <DialogCancelInstallment isEnterprise={isEnterprise} isMainInstallment={isMainInstallment} />
            </WrapperDirection>
          </DialogContent>
          <DialogActions>
            <Button startIcon={<Cancel />} color="error" onClick={handleCloseClick}>
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  else
    return null;
}

export default DialogRouteActions;
