import { SearchInput, DateInput, ReferenceInput, SelectInput } from "react-admin";

const FilterCashRegister = [
  <SearchInput source="q" alwaysOn />,
  <DateInput source="date" label="Data" parse={(date) => (date ? date.toISOString() : null)} />,
  <ReferenceInput label="Status" source="status_cash_register" reference="setup/customer/status-cash-register">
    <SelectInput
      label="Status"
      optionText="name"
      optionValue="type"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>
];

export default FilterCashRegister;
