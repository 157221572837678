import {
  useRecordContext,
  useRedirect
} from 'react-admin';
import Button from "./button";
import IconEdit from '@mui/icons-material/Edit';

function EditInstallmentPenaltyButton({ isEnterprise = true, isClient = true })
{
  const record = useRecordContext();
  const redirect = useRedirect();

  const btnClick = (e) =>
  {
    e.stopPropagation();

    redirect(('/enterprise-installment-penalty/' + record.id));
  };

  return (
    <Button label="Editar" color="info" onClick={btnClick}>
      <IconEdit />
    </Button>
  );
}

export default EditInstallmentPenaltyButton;
