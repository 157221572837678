import { usePermissions } from 'react-admin';
import WrapperDirection from "../wrapper-direction";
import DetailsButton from "../buttons/details-button";
import EditInstallmentPenaltyButton from "../buttons/edit-installment-penalty-button";
import config from "../../config";

const ButtonsInstallmentPenalty = () =>
{
  const { permissions } = usePermissions();

  return (
    <WrapperDirection>
      { permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.installment_penalties.show)) && <DetailsButton /> }
      { permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.installment_penalties.edit)) && <EditInstallmentPenaltyButton /> }
    </WrapperDirection>
  );
};

export default ButtonsInstallmentPenalty;
