import { SearchInput, ReferenceInput, SelectArrayInput, SelectInput, AutocompleteInput, DateInput } from "react-admin";
import { Chip } from "@mui/material";

const FilterEnterpriseInstallment = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Status da Parcela" source="status_installment" reference="setup/enterprise/status-installment">
    <SelectArrayInput
      label="Status da Parcela"
      optionText="name"
      optionValue="type"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <ReferenceInput label="Cliente" source="client_id" reference="setup/enterprise/clients">
    <AutocompleteInput
      label="Cliente"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      limitChoicesToValue={true}
      shouldRenderSuggestions={(val) => { return val ? val.trim().length > 1 : false }}
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <ReferenceInput label="Colaborador" source="customer_id" reference="setup/enterprise/customers">
    <SelectInput
      label="Colaborador"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <ReferenceInput label="Forma de Pagamento" source="enterprise_payment_form_id" filter={{ allow_enterprise_installment: true }} reference="setup/enterprise/payment-forms">
    <SelectInput
      label="Forma de Pagamento"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <DateInput source="date_billing" label="Data de Início Cobrança" parse={(date) => (date ? date.toISOString() : null)} />,
  <DateInput source="date_end_billing" label="Data Final Cobrança" parse={(date) => (date ? date.toISOString() : null)} />,
  <DateInput source="date_payment" label="Data de Início Recebimento" parse={(date) => (date ? date.toISOString() : null)} />,
  <DateInput source="date_end_payment" label="Data Final Recebimento" parse={(date) => (date ? date.toISOString() : null)} />,
  <Chip source="is_last_installment" label="Última Parcela" defaultValue={true} />
];

export default FilterEnterpriseInstallment;
