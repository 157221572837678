import {
  Form,
  TextInput,
  SelectInput,
  FileInput,
  FileField,
  FormDataConsumer,
  useNotify,
  useRecordContext,
  useRefresh
} from "react-admin";
import { AttachFile, Cancel, Save } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import * as Yup from "yup";
import useToggle from "../../ra/hook/useToggle";
import useForRequest from "../../ra/hook/useForRequest";
import Button from "../buttons/button";
import CustomRichTextInput from "../inputs/custom-rich-text-input";
import { yupResolver } from "@hookform/resolvers/yup";
import { leastOneOption, hasValue, file } from "../../utils/schemas";

const Schema = Yup.object({
  type_file: leastOneOption(),
  file_name: hasValue(),
  file: Yup.bool()
    .nullable()
    .when("type_file", { is: 1, then: () => file() }),
  html_string: Yup.bool()
    .nullable()
    .when("type_file", { is: 2, then: () => hasValue() })
});

function DialogClientAddFile({ isEnterprise = true, isClient = true })
{
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const { on, toggle } = useToggle();
  const { loading, action } = useForRequest();

  const handleClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleCloseClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleSubmit = async (values) =>
  {
    var url;

    if (isEnterprise)
    {
      if (isClient)
        url = (values.type_file === 1 ? `client/${record.id}/add/file` : `client/${record.id}/add/document`);
      else
        url = (values.type_file === 1 ? `client-prospection/${record.id}/add/file` : `client-prospection/${record.id}/add/document`);
    }
    else
    {
      if (isClient)
        url = (values.type_file === 1 ? `customer-client/${record.id}/add/file` : `customer-client/${record.id}/add/document`);
      else
        url = (values.type_file === 1 ? `customer-client-prospection/${record.id}/add/file` : `customer-client-prospection/${record.id}/add/document`);
    }

    const { error } = await action(url, 'POST', values);
    if (error)
    {
      notify(error.response.data.error, { type: "error" });
      return;
    }

    refresh();
    toggle();
    notify('Arquivo adicionado com sucesso!', { type: "success" });
  };

  return (
    <>
      <Button onClick={handleClick} label="ARQUIVO">
        <AttachFile />
      </Button>

      <Dialog
        fullWidth
        open={on}
        onClose={handleCloseClick}
        aria-label="Novo Arquivo">
        <Form
          resolver={yupResolver(Schema)}
          onSubmit={handleSubmit}>
          <DialogTitle>
            Novo Arquivo
          </DialogTitle>
          <DialogContent>
            <SelectInput source="type_file" label="Tipo de Arquivo" choices={[ { id: 1, name: 'Arquivo' }, { id: 2, name: 'Texto' } ]} fullWidth isRequired />
            <TextInput source="file_name" label="Nome" fullWidth isRequired />
            <FormDataConsumer>
            {
              ({ formData, ...rest }) => formData.type_file === 1 ?
              <FileInput source="file" label="Arquivo" isRequired>
                <FileField source="src" title="title" />
              </FileInput>
              : formData.type_file === 2 ?
              <CustomRichTextInput source="html_string" label="Texto" fullWidth isRequired />
              : null
            }
            </FormDataConsumer>
          </DialogContent>
          <DialogActions>
            <Button
              label="Cancelar"
              disabled={loading}
              color="error"
              onClick={handleCloseClick}>
              <Cancel />
            </Button>
            <Button
              label="Salvar"
              color="success"
              type="submit"
              disabled={loading}>
              <Save />
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}

export default DialogClientAddFile;
