import { SearchInput, ReferenceInput, SelectInput, DateInput } from "react-admin";

const FilterEnterpriseVehiclePenalty = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Veículo" source="enterprise_vehicle_id" reference="setup/enterprise/vehicles">
    <SelectInput
      label="Veículo"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <ReferenceInput label="Status da Infração" source="status_penalty_type" reference="setup/enterprise/status-penalty">
    <SelectInput
      label="Status da Infração"
      optionText="name"
      optionValue="type"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <ReferenceInput label="Conta Bancária" source="enterprise_bank_account_id" reference="setup/enterprise/bank-accounts">
    <SelectInput
      label="Forma de Pagamento"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <ReferenceInput label="Forma de Pagamento" source="enterprise_payment_form_id" reference="setup/enterprise/payment-forms">
    <SelectInput
      label="Forma de Pagamento"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <DateInput source="date_due_init" label="Data de Início Vencimento" parse={(date) => (date ? date.toISOString() : null)} />,
  <DateInput source="date_due_end" label="Data Final Vencimento" parse={(date) => (date ? date.toISOString() : null)} />
];

export default FilterEnterpriseVehiclePenalty;
