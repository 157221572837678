import { useState } from "react";

function useToggle(initialValue = false)
{
  const [on, setOn] = useState(initialValue);

  const toggle = () =>
  {
    setOn((on) => !on);
  };

  return {
    on,
    toggle
  };
}

export default useToggle;
