import { usePermissions, useRecordContext } from "react-admin";
import { Settings, Cancel } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import config from '../../config';
import useToggle from "../../ra/hook/useToggle";
import WrapperDirection from "../wrapper-direction";
import ShowInstallmentButton from '../buttons/show-installment-button';
import DialogInstallmentFullReceived from './dialog-installment-full-received';
import DialogInstallmentPartialReceived from './dialog-installment-partial-received';
import DialogInstallmentExtend from './dialog-installment-extend';
import DialogInstallmentPenalty from './dialog-installment-penalty';
import EditInstallmentButton from '../buttons/edit-installment-button';
import DialogInstallmentNote from './dialog-installment-note';
import DialogInstallmentClientAbsent from './dialog-installment-client-absent';
import DialogCancelInstallment from './dialog-cancel-installment';

function DialogInstallmentActions({ isEnterprise = true, isMainInstallment = true })
{
  const { permissions } = usePermissions();
  const record = useRecordContext();
  const { on, toggle } = useToggle();

  const handleClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  const handleCloseClick = (e) =>
  {
    e.stopPropagation();
    toggle();
  };

  // IF IS PENALTY OR STATUS IS CANCELED
  if ((record.type_item && record.type_item === config.type_item_contract.penalty) ||
      record.status_installment_type === config.status_installment.canceled)
    return null;
  // IF NOT PERMISSIONS
  else if (isEnterprise &&
           (!permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.installment_actions.full_receive)) &&
            !permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.installment_actions.partial_receive)) &&
            !permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.installment_actions.extended)) &&
            !permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.installment_actions.penalty)) &&
            !permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.installments.edit)) &&
            !permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.installments.cancel))))
    return null;
  // IF IS ENTERPRISE AND STATUS IS PAY OR PAY PARTIAL OR EXTENDED OR LOSS AND NOT EDIT PERMISSION
  else if (isEnterprise &&
           (record.status_installment_type === config.status_installment.pay ||
            record.status_installment_type === config.status_installment.pay_partial ||
            record.status_installment_type === config.status_installment.extended ||
            record.status_installment_type === config.status_installment.loss) &&
            !permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.installments.edit)))
    return null;
  else
    return (
      <>
        {
          isEnterprise ?
          <Button startIcon={<Settings />} color="success" onClick={handleClick}>
            Ações
          </Button> :
          <Button color="success" onClick={handleClick} >
            <Settings />
          </Button>
        }

        <Dialog
          open={on}
          onClose={handleCloseClick}
          aria-label="Ações">
          <DialogTitle>Selecione uma opção</DialogTitle>
          <DialogContent>
            <WrapperDirection direction="column">
              <ShowInstallmentButton isEnterprise={isEnterprise} isMainInstallment={isMainInstallment} />
              {
                (!isEnterprise || (isEnterprise && permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.installment_actions.full_receive)))) &&
                <DialogInstallmentFullReceived isEnterprise={isEnterprise} isMainInstallment={isMainInstallment} />
              }
              {
                (!isEnterprise || (isEnterprise && permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.installment_actions.partial_receive)))) &&
                <DialogInstallmentPartialReceived isEnterprise={isEnterprise} isMainInstallment={isMainInstallment} />
              }
              {
                (!isEnterprise || (isEnterprise && permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.installment_actions.extended)))) &&
                <DialogInstallmentExtend isEnterprise={isEnterprise} isMainInstallment={isMainInstallment} />
              }
              {
                (!isEnterprise || (isEnterprise && permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.installment_actions.penalty)))) &&
                <DialogInstallmentPenalty isEnterprise={isEnterprise} isMainInstallment={isMainInstallment} />
              }
              {
                isEnterprise && permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.installments.edit)) &&
                <EditInstallmentButton isEnterprise={isEnterprise} isMainInstallment={isMainInstallment} />
              }
              <DialogInstallmentNote isEnterprise={isEnterprise} isMainInstallment={isMainInstallment} />
              <DialogInstallmentClientAbsent isEnterprise={isEnterprise} isMainInstallment={isMainInstallment} />
              {
                isEnterprise && permissions.permissions.some(e => e.keys.some(k => k.key === config.permissions.installments.cancel)) &&
                <DialogCancelInstallment isEnterprise={isEnterprise} isMainInstallment={isMainInstallment} />
              }
            </WrapperDirection>
          </DialogContent>
          <DialogActions>
            <Button startIcon={<Cancel />} color="error" onClick={handleCloseClick}>
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
}

export default DialogInstallmentActions;
