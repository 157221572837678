import { SearchInput, ReferenceInput, SelectInput, DateInput } from "react-admin";
import { Chip } from "@mui/material";

const FilterEnterpriseWithdrawal = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Tipo" source="enterprise_type_withdrawal_id" reference="setup/enterprise/types-withdrawal">
    <SelectInput
      label="Tipo"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <ReferenceInput label="Colaborador" source="customer_id" reference="setup/enterprise/customers">
    <SelectInput
      label="Colaborador"
      optionText="name"
      optionValue="id"
      variant="outlined"
      margin="none"
      sx={{ backgroundColor: "white" }}/>
  </ReferenceInput>,
  <Chip source="is_enterprise_cash" label="Retirado do Caixa da Empresa" defaultValue={true} />,
  <DateInput source="date_withdrawal" label="Data de Início Retirada" parse={(date) => (date ? date.toISOString() : null)} />,
  <DateInput source="date_end_withdrawal" label="Data Final Retirada" parse={(date) => (date ? date.toISOString() : null)} />
];

export default FilterEnterpriseWithdrawal;
